import React from "react";
import ProfilePhoto from "../assets/ProfilePhoto.jpg";
import "./Section.css";

import { TypeAnimation } from 'react-type-animation';

const Section = () => {
  return (
    <div id="home" className="section">
      <img src={ProfilePhoto} alt="ProfilePhoto"></img>
      <h1>
        <span>Gayathri Padmanabhan </span>
      </h1>
<div style={{height:'5vh'}}>


      <TypeAnimation
      sequence={[
        'Full-Stack Engineer', 
        1000, // Waits 1s
        'Delivery Lead', // Deletes 'One' and types 'Two'
        900, // Waits .9s
        'Hands-on Developer',
        800,
        'Programming Enthusiast', 
        800,
        () => {
          //console.log('Sequence completed');
        },
      ]}
      preRenderFirstString={true}
      wrapper="span"
      omitDeletionAnimation
      cursor={false}
      repeat={Infinity}
      style={{ fontSize: '2em', display: 'inline-block' }}
    />
    </div>
      <p>
        With over 12 years of experience, I specialize in front-end development with expertise in React, Redux,
        JavaScript,Typescript, Vue.js and Vuex.
      
        Beyond front-end mastery, my proficiency extends to API development,
        using Java Spring Boot and Node JS coupled with a deep understanding of SQL.
      
        {/* With a relentless commitment to crafting efficient RESTful services,
        coupled with a deep understanding of SQL, I thrive in delivering
        top-notch results. */}
      </p>
      <div className="section-action">
        <div className="section-connect"><a style={{textDecoration:'none'}} href="#contact">Connect with Me</a></div>
        <div className="section-aboutme"><a style={{textDecoration:'none', color:'#FFDDF4'}} href="about">About Me</a></div>
      </div>
    </div>
  );
};

export default Section;
