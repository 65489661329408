import React from 'react'
import './Mywork.css'
import mywork_data from '../assets/mywork-data'
import arrow  from '../assets/Arrow_old.jpg'

const Mywork = () => {
  return (
    <div id="work" className="mywork">
        <div className="mywork-title">
            <h1>
                My Personal Projects
            </h1>
        </div>
        <div className="mywork-container">
            {mywork_data.map((work,index)=>{

                return(<figure key={index} >

           
               
               
                    <img  src={work.w_image} alt={work.w_name}></img>
                    <figcaption><a href={work.w_link}>{work.w_name}</a></figcaption>
                
              
                   
                    </figure>
                )

            })}
        </div>
        <div className="mywork-showmore">
            <a href="https://github.com/gkpshere">My Github</a>
            <img src={arrow} alt="Arrow" style={{width:'50px', borderRadius:'50%'}}></img>
        </div>
    </div>
  )
}

export default Mywork