import React, { useRef, useState } from "react";
import "./Navbar.css";
import GPLogo from "../assets/GPLogo.png";
import AnchorLink from "react-anchor-link-smooth-scroll";
import menu_open from '../assets/menu_open.svg'
import menu_close from '../assets/menu_close.svg'

const NavBar = () => {
  const [menu, setMenu] = useState("home");
  const menuRef= useRef();

  const openMenu = () => {
    menuRef.current.style.right = "0px";
  };

  const closeMenu = () => {
    menuRef.current.style.right = "-400px";
  };
  return (
    <div id="nav" className="navbar">
      <img className="logo" src={GPLogo} alt="Gayathri Padmanabhan"></img>
      <img src={menu_open} onClick={openMenu} alt="menu_open" className="nav-mob-open"></img>
      <ul ref={menuRef} className="nav-menu">
        <img src={menu_close} onClick={closeMenu} alt="" className="nav-mob-close"></img>
        <AnchorLink className="anchor-link" offset={50} href="#home">
          {" "}
          <li
            className={menu === "home" ? "underline" : ""}
            onClick={() => {
              setMenu("home");
              closeMenu();
            }}
          >
            Home
          </li>
        </AnchorLink>
        <AnchorLink className="anchor-link" offset={50} href="#about">
          <li
            className={menu === "about" ? "underline" : ""}
            onClick={() => {
              setMenu("about");
              closeMenu();
            }}
          >
            About Me
          </li>
        </AnchorLink>
        {/* <AnchorLink className="anchor-link" offset={50} href="#tools">
          <li
            className={menu === "tools" ? "underline" : ""}
            onClick={() => {
              setMenu("tools");
            }}
          >
            Tools/IDE
          </li>
        </AnchorLink> */}
        <AnchorLink className="anchor-link" offset={50} href="#work">
          <li
            className={menu === "work" ? "underline" : ""}
            onClick={() => {
              setMenu("work");
              closeMenu();
            }}
          >
            Projects
          </li>
        </AnchorLink>
        <AnchorLink className="anchor-link" offset={50} href="#contact">
          <li
            className={menu === "contact" ? "underline" : ""}
            onClick={() => {
              setMenu("contact");
              closeMenu();
            }}
          >
            Contact
          </li>
        </AnchorLink>
      </ul>
      <div className="nav-connect" ><a onClick={() => {
              setMenu("contact");
              closeMenu();
            }} href="#contact">Connect With Me</a></div>
    </div>
  );
};

export default NavBar;
