import React from 'react'
import './Footer.css'
import GPLogo  from '../assets/GPLogo.png'
import user_icon from '../assets/user_icon.svg'

const Footer = () => {
  return (
   <div className="footer">
    <div className="footer-top">
        <div className="footer-top-left">
            <img src={GPLogo} alt="Footer" />
            {/* <p>
            Let’s work together and make something great. Drop me a line at <span style={{textDecoration:'underline'}}> gkpshere@gmail.com </span>

                
                </p> */}
               
        </div>
        <div className="footer-top-right">
        <p>
            Drop me a line at <span style={{textDecoration:'underline'}}> gkpshere@gmail.com </span>

                
                </p>
            {/* <div className="footer-email-input"> */}
                {/* <img src={user_icon} alt="User" /> */}
                {/* <input type="email" name="" id="" placeholder='Enter your email' /> */}
            {/* </div> */}
            {/* <div className="footer-subscribe">Get Resume</div> */}
        </div>
    </div>
    <hr></hr>
    <div className="footer-bottom">
        <p className="footer-bottom-left"></p>
        <div className="footer-bottom-right">
           
            <p><a href="privacy">Privacy Policy</a></p>
           
        </div>
    </div>
   </div>
  )
}

export default Footer