import Project_1 from '../assets/Project_1.jpg'
import Project_2 from '../assets/Project_2.jpg'
import Project_3 from '../assets/Project_3.png'
import Project_4 from '../assets/Project_4.png'
import Project_5 from '../assets/Project_5.png'
import Project_6 from '../assets/Project_6.png'

const mywork_data=[
    {
        w_no:1,
        w_name:"ReactProject",
        w_link:"https://github.com/gkpshere/MyReactCode",
        w_image:Project_1

    },
    {
        w_no:2,
        w_name:"Vue JS/Vite",
        w_link:"https://github.com/gkpshere/MyViteProject",
        w_image:Project_2

    },
    {
        w_no:3,
        w_name:"ToDo List Typescript",
        w_link:"https://github.com/gkpshere/ToDoListTypeScript",
        w_image:Project_3

    },
    {
        w_no:4,
        w_name:"DSA Java",
        w_link:"https://github.com/gkpshere/Algorithms",
        w_image:Project_4

    },
   
    {
        w_no:5,
        w_name:"Python Scripts",
        w_link:"https://github.com/gkpshere/TimeSaverScript",
        w_image:Project_5

    },
    {
        w_no:6,
        w_name:"Java Spring Boot",
        w_link:"https://github.com/gkpshere/SpringJDBC",
        w_image:Project_6

    }
]

export default mywork_data;