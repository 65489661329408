import React from "react";
import AboutMeFinal from "../assets/AboutMeFinal.jpg";
import "./About.css";

const About = () => {
  return (
    <div id="about" className="about">
      <div className="about-title">
        <h1>About me</h1>
      </div>
      <div className="about-sections">
        <div className="about-left">
          {/* Image comes here */}
          <img
            src={AboutMeFinal}
            alt="AboutMe"
            style={{ width: "400px" }}
          ></img>
        </div>
        <div className="about-right">
          <div className="about-para">
            {/*  I possess a strong expertise in front-end development, leveraging
              React, Redux, JavaScript, and Vue.js to create user-friendly and
              engaging interfaces. Additionally, I hold a solid understanding of
              API development using Node.js and Java Spring Boot, allowing me to
              build efficient back-end systems. */}
            <p>
              {/* My background reflects a
              T-shaped skillset, combining deep technical knowledge with a
              comprehensive grasp of software development principles. I excel in
              applying Object-Oriented Programming (OOP) methodologies and have
              a proven track record of success in both mobile application and
              web development projects. */}

Hello there! I'm currently based in Canada, equipped with a T-shaped skillset that blends depth in front-end development with a broad understanding of API development.<br></br>

My journey in the tech world has been vibrant and diverse. From crafting intuitive customer-facing applications for a leading American insurance giant to contributing to a healthcare mobile app trusted by over a million users in South East Asia, I've had the privilege to immerse myself in impactful projects that touch lives across continents.<br></br>

Along the way, my dedication and expertise have been recognized with various accolades, including the esteemed "Ace" award bestowed upon me by Mphasis, a testament to my commitment to excellence in everything I do.
              <br>
              </br>
              <span style={{fontStyle:'italic',color:'#d8d8d8'}}>"This portfolio website is a testament to my skills. Built using React JS, it offers a seamless experience across all devices."</span>
            </p>
          </div>
          <div className="about-skills">
            <div className="about-skill">
              <p>HTML & CSS</p>
              <hr style={{ width: "90%" }} />
            </div>
            <div className="about-skill">
              <p>React JS, Redux</p>
              <hr style={{ width: "90%" }} />
            </div>
            <div className="about-skill">
              <p>JavaScript</p>
              <hr style={{ width: "90%" }} />
            </div>
            <div className="about-skill">
              <p>Vue JS,Vuex</p>
              <hr style={{ width: "60%" }} />
            </div>
            <div className="about-skill">
              <p>Node JS</p>
              <hr style={{ width: "30%" }} />
            </div>
            <div className="about-skill">
              <p>Java 8</p>
              <hr style={{ width: "40%" }} />
            </div>
            <div className="about-skill">
              <p>Java Spring Boot</p>
              <hr style={{ width: "40%" }} />
            </div>
            <div className="about-skill">
              <p>SQL</p>
              <hr style={{ width: "40%" }} />
            </div>
          </div>
        </div>
      </div>
      <div className="about-achievements">
        <div className="about-achievement">
          <h1>11+</h1>
          <p>   Years   </p>
        </div>
        <div className="about-achievement">
          <h1>25+</h1>
          <p>Projects</p>
        </div>
        <div className="about-achievement">
          <h1>12+</h1>
          <p>Certifications</p>
        </div>
      </div>
    </div>
  );
};

export default About;
