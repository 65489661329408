import './App.css';
import Home from './Home'

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivacyPolicy from './Components/PrivacyPolicy';

function App() {
  return (
    <Router>

 
    <div className="App">
      {/* <Home/> */}
    </div>

    <Routes>
    <Route
                            path="/"
                            element={<Home />}
                        ></Route>
                       
                        <Route
                            path="/privacy"
                            element={<PrivacyPolicy />}
                        ></Route>
                    </Routes>

    </Router>
  );
}

export default App;
