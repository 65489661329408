import React from 'react'
import NavBar from './Components/NavBar';
import Section from './Components/Section'
import About from './Components/About';
import Mywork from './Components/Mywork';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import Tools from './Components/Tools';

const Home = () => {
  return (
    <div>
        <NavBar>

</NavBar>
<Section></Section>
<About></About>
<Mywork></Mywork>
{/* <Tools></Tools> */}
<Contact></Contact>

<Footer></Footer>
    </div>
  )
}

export default Home