import React from "react";
import "./Contact.css";
import mail_icon from "../assets/mail_icon.svg";
import location_icon from "../assets/location_icon.svg";
import call_icon from "../assets/call_icon.svg";
import LinkedIn from "../assets/linkedin-svgrepo-com.svg"

const Contact = () => {

  const [result, setResult] = React.useState("");
  const [spinner,setSpinner]= React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setSpinner("fa fa-spinner fa-spin")
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "a10fa382-d9ac-4eb1-915d-651b950e7b3d");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Message sent successfully!");
      setSpinner("")
      event.target.reset();
    } else {
      console.log("Error", data);
      setSpinner("")
      setResult(data.message);
    }
  };

  return (
    <div id="contact" className="contact">
      <div className="contact-title">
        <h1>Contact Me</h1>
      </div>
      <div className="contact-section">
        <div className="contact-left">
          <h1>Let's talk</h1>
          <p>
            Check out my LinkedIn profile and feel free to drop me a message and I would be happy to respond.
          </p>
          <div className="contact-details">
          <div className="contact-detail">
              <img src={LinkedIn} alt="LinkedIn" style={{maxWidth:'40px', background:'#d8d8d8', borderRadius:"40px"}} />
              <p><a style={{ color:'#d8d8d8'}} href="https://www.linkedin.com/in/gayathri-padmanabhan/">LinkedIn Profile
                </a></p>
            </div>
            <div className="contact-detail">
              <img src={mail_icon} alt="Mail" />
              <p>gkpshere@gmail.com</p>
            </div>
            <div className="contact-detail">
              <img src={location_icon} alt="Location" />
              <p>Ontario, Canada</p>
            </div>
            <div className="contact-detail">
              <img src={call_icon} alt="Call" />
              <p>+1-(647)-761-1685 </p>
            </div>
          </div>
        </div>
        <form onSubmit={onSubmit} className="contact-right">
          <label htmlFor="">Your name:</label>
          <input type="text" name="name" id="contact_name" placeholder="Enter your name"/>
          <label htmlFor="">Your email address:</label>
          <input type="email" name="email" id="contact_email" placeholder="Enter you email address" />
          <label htmlFor="">Write your message here:</label>
          <textarea name="message" id="contact_message" rows="8" placeholder="Enter your message"></textarea>
          <button type='submit' className="contact-submit"> 
            <i class={spinner}></i>
            Submit</button>
            <span>{result}</span>

        </form>
       
      </div>
    </div>
  );
};

export default Contact;
